import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "input-textarea--select"
    }}>{`Input, Textarea & Select`}</h2>
    <form className="row">
  <div className="input-field black-input column column-first xlarge-6 small-12">
    <span className="user-icon"></span>
    <input type="text" />
  </div>
  <div className="input-field black-input column column-last xlarge-6 small-12">
    <span className="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>
  <div className="input-field black-input column column-first xlarge-6 small-12">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>
  <div className="input-field black-input text-area-field column column-last xlarge-6 small-12">
    <span className="edit-icon"></span>
    <textarea></textarea>
  </div>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <div class="input-field black-input">
    <span class="user-icon"></span>
    <input type="text" />
  </div>

  <div class="input-field black-input">
    <span class="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>

  <div class="input-field black-input">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>

  <div class="input-field black-input text-area-field">
    <span class="edit-icon"></span>
    <textarea></textarea>
  </div>
</form>
`}</code></pre>
    <form className="row">
  <div className="input-field green-input column column-first xlarge-6 small-12">
    <span className="user-icon"></span>
    <input type="text" />
  </div>
  <div className="input-field green-input column column-last xlarge-6 small-12">
    <span className="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>
  <div className="input-field green-input column column-first xlarge-6 small-12">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>
  <div className="input-field green-input text-area-field column column-last xlarge-6 small-12">
    <span className="edit-icon"></span>
    <textarea></textarea>
  </div>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <div class="input-field green-input">
    <span class="user-icon"></span>
    <input type="text" />
  </div>

  <div class="input-field green-input">
    <span class="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>

  <div class="input-field green-input">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>

  <div class="input-field green-input text-area-field">
    <span class="edit-icon"></span>
    <textarea></textarea>
  </div>
</form>
`}</code></pre>
    <form className="row">
  <div className="input-field orange-input column column-first xlarge-6 small-12">
    <span className="user-icon"></span>
    <input type="text" />
  </div>
  <div className="input-field orange-input column column-last xlarge-6 small-12">
    <span className="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>
  <div className="input-field orange-input column column-first xlarge-6 small-12">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>
  <div className="input-field orange-input text-area-field column column-last xlarge-6 small-12">
    <span className="edit-icon"></span>
    <textarea></textarea>
  </div>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form class="row">
  <div class="input-field orange-input">
    <span class="user-icon"></span>
    <input type="text" />
  </div>

  <div class="input-field orange-input">
    <span class="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>

  <div class="input-field orange-input">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>

  <div class="input-field orange-input text-area-field">
    <span class="edit-icon"></span>
    <textarea></textarea>
  </div>
</form>
`}</code></pre>
    <form className="row">
  <div className="input-field purple-input column column-first xlarge-6 small-12">
    <span className="user-icon"></span>
    <input type="text" />
  </div>
  <div className="input-field purple-input column column-last xlarge-6 small-12">
    <span className="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>
  <div className="input-field purple-input column column-first xlarge-6 small-12">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>
  <div className="input-field purple-input text-area-field column column-last xlarge-6 small-12">
    <span className="edit-icon"></span>
    <textarea></textarea>
  </div>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form class="row">
  <div class="input-field purple-input">
    <span class="user-icon"></span>
    <input type="text" />
  </div>

  <div class="input-field purple-input">
    <span class="email-icon"></span>
    <input type="email" placeholder="Enter your email..." />
  </div>

  <div class="input-field purple-input">
    <select>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
      <option value="">Unnamed</option>
    </select>
  </div>

  <div class="input-field purple-input text-area-field">
    <span class="edit-icon"></span>
    <textarea></textarea>
  </div>
</form>
`}</code></pre>
    <h2 {...{
      "id": "checkox-and-radio-buttons"
    }}>{`Checkox and radio buttons`}</h2>
    <form className="push-down">
  <label>
    <input type="checkbox" className="black-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="black-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="black-checkbox" />
    <span></span>
  </label>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <label>
    <input type="checkbox" class="black-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="black-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="black-checkbox" />
    <span></span>
  </label>
</form>
`}</code></pre>
    <form className="push-down">
  <label>
    <input type="checkbox" className="green-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="green-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="green-checkbox" />
    <span></span>
  </label>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <label>
    <input type="checkbox" class="green-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="green-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="green-checkbox" />
    <span></span>
  </label>
</form>
`}</code></pre>
    <form className="push-down">
  <label>
    <input type="checkbox" className="orange-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="orange-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="orange-checkbox" />
    <span></span>
  </label>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <label>
    <input type="checkbox" class="orange-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="orange-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="orange-checkbox" />
    <span></span>
  </label>
</form>
`}</code></pre>
    <form className="push-down">
  <label>
    <input type="checkbox" className="purple-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="purple-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" className="purple-checkbox" />
    <span></span>
  </label>
    </form>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<form>
  <label>
    <input type="checkbox" class="purple-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="purple-checkbox" />
    <span></span>
  </label>
  <label>
    <input type="radio" name="yow" class="purple-checkbox" />
    <span></span>
  </label>
</form>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      